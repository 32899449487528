<template>
  <ChatList />
</template>

<script>
import ChatList from "@/components/chats/ChatList"

export default {
  name: "Chats",
  components: {
    ChatList,
  },
}
</script>

<style scoped></style>
