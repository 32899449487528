<template>
  <div>
    <div>
      <a-button
        v-if="isBuyer"
        @click="onCreateChatClick"
        type="primary"
        icon="wechat"
      >
        Добавить Чат
      </a-button>
    </div>

    <div class="content-container">
      <a-table
        bordered
        row-key="id"
        :customRow="customRowAction"
        :columns="columns"
        :data-source="chats"
        :pagination="false"
      >
        <span slot="created_at" slot-scope="value">{{ value | date }}</span>
        <span slot="action" slot-scope="text, record">
          <a-badge :count="record.msg_new_cnt">
            <a-avatar shape="square">{{ record.msg_cnt }}</a-avatar>
          </a-badge>
        </span>
      </a-table>

      <a-pagination
        class="pagination"
        v-model="currentPage"
        :page-size="paginationOptions.pageSize"
        :total="paginationOptions.totalCount"
      />
    </div>

    <a-modal
      :visible="dlgCreateChat"
      title="Новый чат"
      centered
      @cancel="dlgCreateChat = false"
    >
      <a-form-model ref="frmCreateChat" :model="chat" :rules="chatRules">
        <a-form-model-item prop="name">
          <a-input
            v-model="chat.name"
            placeholder="Укажите название нового чата"
          />
        </a-form-model-item>
        <a-form-model-item prop="message">
          <a-textarea
            v-model="chat.message"
            placeholder="Ваше сообщение"
            rows="5"
          />
        </a-form-model-item>
      </a-form-model>

      <template v-slot:footer>
        <a-button key="back" @click="dlgCreateChat = false">Отменить</a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="frmCreateChatLoading"
          @click="onCreateChatSubmit"
        >
          Сохранить
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import moment from "moment/moment"

export default {
  name: "ChatList",

  data() {
    return {
      columns: [
        {
          title: "Дата создания",
          dataIndex: "created_at",
          width: 150,
          scopedSlots: { customRender: "created_at" },
        },
        {
          title: "Дилер",
          dataIndex: "client_name",
          width: 200,
        },
        {
          title: "Название",
          dataIndex: "name",
        },
        {
          title: "",
          key: "action",
          width: 30,
          scopedSlots: { customRender: "action" },
        },
      ],
      dlgCreateChat: false,
      frmCreateChatLoading: false,
      chat: { name: "", message: "" },
      chatRules: {
        name: [
          {
            required: true,
            message: "Название обязательно",
            trigger: "change",
          },
        ],
        message: [
          {
            required: true,
            message: "Сообщение обязательно",
            trigger: "change",
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      isBuyer: "auth/isBuyer",
      isDealer: "auth/isDealer",
      chats: "chats/chats",
      paginationOptions: "chats/paginationOptions",
    }),
    currentPage: {
      get() {
        return this.paginationOptions.currentPage
      },
      set(page) {
        this.setCurrentPage(page)
      },
    },
  },
  watch: {
    async currentPage(page) {
      if (page !== 1) {
        this.getChats()
      }
      await this.getChats()
    },
  },
  filters: {
    date(value) {
      return moment(value).format("DD.MM.YYYY HH:mm")
    },
  },
  mounted() {
    this.getChats()
  },
  methods: {
    ...mapMutations({
      setCurrentPage: "chats/SET_CURRENT_PAGE",
    }),
    ...mapActions({
      getChats: "chats/getChats",
      createChat: "chats/createChat",
    }),
    customRowAction(record) {
      return {
        on: {
          click: () => {
            this.$router.push(`/chat/${record.id}`)
          },
        },
      }
    },
    onCreateChatClick() {
      if (this.$refs.frmCreateChat) this.$refs.frmCreateChat.resetFields()
      this.dlgCreateChat = true
    },
    onCreateChatSubmit() {
      this.$refs.frmCreateChat.validate(valid => {
        if (valid) {
          this.createChat(this.chat)
          this.dlgCreateChat = false
        }
      })
    },
  },
}
</script>

<style scoped>
::v-deep .ant-table-tbody > tr {
  cursor: pointer;
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>
